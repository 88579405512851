<template>
  <MenuList v-if="topics">
    <MenuListItem
      v-for="(topic, key) in topics"
      :key="key"
      v-bind="topic"
      with-icon
      heading="h3"
    />
  </MenuList>
</template>

<script lang="ts" setup>
const { data: topics } = await useAsyncGraphqlQuery('topicsOverview', null, {
  transform: (v) => v.data.menuByName?.links,
  graphqlCaching: {
    client: true,
  },
})
</script>
